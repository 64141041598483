<template>
    <div class="h-screen w-full bg-img vx-row no-gutter items-center justify-center">
        <div class="w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
                <vx-card>
                    <div slot="no-body" class="full-page-bg-color">
                        <div class="vx-row no-gutter justify-center items-center">
                            <div class="vx-col hidden lg:block lg:w-1/2">
                                <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
                            </div>

                            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                                <div class="p-8 login-tabs-container">
                                    <div class="vx-card__title mb-4">
                                        <h4 class="mb-4">Reset Password</h4>
                                        <p>Can't remember your password? Let's create a new one.</p>
                                        <!-- <label for="" v-if="env != 'prod'" class="ms-4 badge bg-label-danger">{{ env
                                            }}</label> -->
                                    </div>

                                    <div>
                                        <form v-on:keyup.enter="reset()">
                                            <vs-input disabled type="text" name="email" icon-no-border
                                                icon="icon icon-mail" icon-pack="feather" label-placeholder="Email"
                                                v-model="email" class="w-full" />
                                            <vs-input type="password" name="password" icon-no-border
                                                icon="icon icon-lock" icon-pack="feather"
                                                label-placeholder="New Password" v-model="password" class="w-full mt-6"
                                                v-validate="'required|min:2|max:30'" />
                                            <span class="text-danger text-sm">{{
                                                errors.first("password")
                                            }}</span>
                                            <vs-input type="password" name="confirm-password" icon-no-border
                                                icon="icon icon-lock" icon-pack="feather"
                                                label-placeholder="Confirm New Password" v-model="confirmPassword"
                                                class="w-full mt-6" v-validate="'required|min:2|max:30'" />
                                            <span class="text-danger text-sm">{{
                                                errors.first("confirm-password")
                                            }}</span>

                                        </form>
                                        <div class="flex flex-wrap justify-between my-5">
                                            <vs-button class="float-right" :disabled="!validateForm"
                                                @click="reset()">Confirm</vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            email: "",
            token: "",
            password: "",
            confirmPassword: ""
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any() && this.email != "" && this.password != "" && this.confirmPassword != "";
        },
    },
    mounted() {
        const token = this.$route.params.id
        this.token = token
        if (!token) {
            return this.$router.push('/')
        }
        this.checkToken(token)
    },
    methods: {
        checkToken: function (token) {
            this.$vs.loading();
            this.$http.get('/api/account/v1/auth/check-token', {
                params: {
                    token
                }
            })
                .then((res) => {
                    this.$vs.loading.close()
                    if (res.code != 200) {
                        this.$vs.notify({
                            title: "Error",
                            text: res.message,
                            iconPack: "feather",
                            icon: "icon-alert-circle",
                            color: "danger",
                            position: "top-right",
                        });
                        return this.$router.push({ name: 'page-login' });
                    }

                    this.email = res.data.email
                })
                .catch((err) => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        time: 2500,
                        title: "Error",
                        text: err.message,
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        color: "danger",
                        position: "top-right",
                    });
                    return false;
                })
        },
        reset: function () {
            console.log('a')
            if (!this.validateForm) {
                this.$vs.notify({
                    color: "danger",
                    title: "Gagal",
                    text: "Lengkapi input",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.loading();
                this.$http.patch('/api/account/v1/auth/reset-password', {
                    token: this.token,
                    new_password: this.password,
                    new_password_confirm: this.confirmPassword
                })
                    .then((res) => {
                        this.$vs.loading.close()
                        if (res.code != 200) {
                            this.$vs.notify({
                                title: "Error",
                                text: res.message,
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                color: "danger",
                                position: "top-right",
                            });
                            return
                        }
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: res.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        return this.$router.push({ name: 'page-login' });

                    })
                    .catch((err) => {
                        this.$vs.loading.close()
                        this.$vs.notify({
                            time: 2500,
                            title: "Error",
                            text: err.message,
                            iconPack: "feather",
                            icon: "icon-alert-circle",
                            color: "danger",
                            position: "top-right",
                        });
                        return false;
                    })
            }
        }
    }
}
</script>

<style lang="scss">
#page-login {
    .social-login-buttons {
        .bg-facebook {
            background-color: #1551b1;
        }

        .bg-twitter {
            background-color: #00aaff;
        }

        .bg-google {
            background-color: #4285f4;
        }

        .bg-github {
            background-color: #333;
        }
    }
}
</style>